import React from "react";

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSupabase } from "./hooks/useSupabase";

export const Login = () => {
  const supabase = useSupabase();

  const [email, setEmail] = React.useState("");
  
  const [magicLinkSent, setMagicLinkSent] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onEmailChange = (event) => setEmail(event.target.value);

  const onSubmit = async (event) => {
    event.preventDefault();

    setMagicLinkSent(false);
    setError(null);

    const { error } = await supabase.auth.signInWithOtp({
      email
    });

    if (error) {
      setError(error.message);

      return;
    }

    setMagicLinkSent(true);
  }

  return (
    <Form onSubmit={onSubmit}>
      { error !== null && (
        <Alert variant="danger">
          <b>Error!</b> {error}
        </Alert>
      ) }

      { magicLinkSent && (
        <Alert variant="primary">
          <b>Magic Link Sent!</b> Please check your e-mail to login.
        </Alert>
      ) }

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={onEmailChange} />
      </Form.Group>

      <Button variant="primary" type="submit">
        Log In
      </Button>
    </Form>
  );
}