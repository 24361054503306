import React from "react";

import { useSupabase } from "./useSupabase";

export const useIsLoggedIn = () => {
  const supabase = useSupabase();

  const [isLoggedIn, setIsLoggedIn] = React.useState(undefined);
  const [userName, setUserName] = React.useState(null);

  React.useEffect(() => {
    const interval = setInterval(() => {
      (async () => {
        if (!supabase) {
          return;
        }
  
        const { data, error } = await supabase.auth.getSession();

        const newLoggedInState = data.session && !error;

        if (newLoggedInState && !isLoggedIn) {
          const { data: userData } = await supabase.from("user").select("id, name").eq("id", data.session.user.id);

          setUserName(userData[0].name);
        }
  
        setIsLoggedIn(newLoggedInState);
      })();
    }, 1000);

    return () => clearInterval(interval);
  }, [isLoggedIn, supabase, userName, setUserName, setIsLoggedIn]);

  return { isLoggedIn, userName };
};