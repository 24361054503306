import React from 'react';
import { createClient } from '@supabase/supabase-js';

export const useSupabase = () => {
  const [supabase, setSupabase] = React.useState(null);

  React.useEffect(() => {
    setSupabase(createClient(
      'https://pjfpxhzivqvjjgbvdhnp.supabase.co', 
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBqZnB4aHppdnF2ampnYnZkaG5wIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ0NTc3NDEsImV4cCI6MjAxMDAzMzc0MX0.VLloDU37diHIyohHE7Tj-N_T3m6iIFEe6E1xOHfjkag'
    ));
  }, []);

  return supabase;
};