import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import { useSupabase } from './hooks/useSupabase';

export const AddGameModal = () => {
  const supabase = useSupabase();

  const [value, setValue] = React.useState('');
  const [showing, setShowing] = React.useState(false);

  const handleShow = () => setShowing(true);
  const handleClose = () => {
    setValue('');
    setShowing(false);
  };

  const onChange = (ev) => setValue(ev.target.value);

  const handleSave = async () => {
    const { data } = await supabase.auth.getSession();

    await supabase.from("game").insert({
      name: value,
      owner: data.session.user.id
    });

    setShowing(false);

    window.location.reload();
  }

  return (
    <>
      <Nav.Link onClick={handleShow}>
        New Game
      </Nav.Link>

      <Modal show={showing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form inline>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
              <Form.Control
                placeholder="Game Name"
                aria-label="Game Name"
                aria-describedby="basic-addon1"
                onChange={onChange}
              />
            </InputGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}