import React from "react";

import { useSupabase } from './hooks/useSupabase';
import { useIsLoggedIn } from './hooks/useIsLoggedIn';

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

export const Listing = () => {
  const supabase = useSupabase();
  const { userName } = useIsLoggedIn();

  const [gameData, setGameData] = React.useState([]);

  const update = React.useCallback(() => {
    if (supabase === null) {
      return;
    }

    (async () => {
      const { data } = await supabase.from("game").select(`
        id,
        created_at,
        name,
        owner (name)
      `).order("name");
      
      const { data: history } = await supabase.from("history").select(`
        game,
        person (name),
        timestamp
      `).order("timestamp", { ascending: false });

      setGameData(data.map(row => {
        const latest = history.find(el => el.game === row.id);

        if (latest === undefined) {
          return {
            ...row,
            current: row.owner
          };
        }

        return {
          ...row,
          current: latest.person
        };
      }));
    })();
  }, [supabase, setGameData]);

  React.useEffect(update, [update]);

  const onChangeCurrentPressed = React.useCallback((id) => {
    (async () => {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

      if (sessionError) {
        console.log(sessionError);

        return;
      }

      const { error } = await supabase.from("history").insert({ game: id, person: sessionData.session.user.id });

      if (error) {
        console.log(error);

        return;
      }

      update();
    })();
  }, [supabase, update]);

  return (
    <Table responsive striped bordered hover>
      <thead>
        <tr className="align-middle">
          <th>🎲 Game Name</th>
          <th>🙎 Owner</th>
          <th>🙇 Current</th>
          <th>🪧 Actions</th>
        </tr>
      </thead>
      <tbody>
        { gameData.map(x => (
          <tr key={x.id} className="align-middle">
            <td><strong>{ x.name }</strong></td>
            <td>{ x.owner.name }</td>
            <td>{ x.current.name }</td>
            <td>
              { x.current.name === userName && ( <Button disabled variant="outline-secondary" size="sm">It's with you!</Button> ) }
              { x.current.name !== userName && ( <Button variant="outline-primary" size="sm" onClick={() => onChangeCurrentPressed(x.id)}>I have it!</Button> ) }
            </td>
          </tr>
        )) }
      </tbody>
    </Table>
  );
};