import React from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import { useIsLoggedIn } from './hooks/useIsLoggedIn';
import { useSupabase } from './hooks/useSupabase';

import { AddGameModal } from './AddGameModal';
import { Listing } from './Listing';
import { Login } from './Login';

function App() {
  const supabase = useSupabase();
  const { isLoggedIn, userName } = useIsLoggedIn();

  const logout = React.useCallback(() => {
    (async () => {
      await supabase.auth.signOut();
    })();
  }, [supabase]);

  return (
    <>
      <Navbar bg="dark" expand="lg" className="bg-body-tertiary" sticky="top">
        <Container>
          <Navbar.Brand href="#home">Board Games</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          { isLoggedIn && (
            <>
              <Navbar.Collapse>
                <AddGameModal />
              </Navbar.Collapse>
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <span>Signed in as {userName}</span> (<a href="#" onClick={logout}>Logout</a>)
                </Navbar.Text>
              </Navbar.Collapse>
            </>
          ) }
        </Container>
      </Navbar>
      <Container bg="dark" className="mt-4">
        { isLoggedIn !== undefined ? (isLoggedIn ? <Listing /> : <Login />) : "Loading..."  }
      </Container>
    </>
  );
}

export default App;
